import { isInvalidResponse } from "~/utils/http/response";
import { newInvalidResponseError, useZap } from "~/composables/logger/zap";
import { download } from "~/utils/s3";
import { parseDateToFullLocale } from "~/utils/date";
import { useSiteSettingsApi } from "~/composables/api/site_settings";

export const useDownloadPriceList = () => {
  const l = useZap("useDownloadPriceList");

  const authStore = useAuthStore();

  const siteSettingsApi = useSiteSettingsApi();

  const is = ref({
    priceListLoading: false,
  });

  const downloadPriceList = async function () {
    if (is.value.priceListLoading) return;

    is.value.priceListLoading = true;

    const _l = l.named("downloadPriceList");

    // Надо обновить сессию перед скачиванием, иначе, если токен протух, будут неактуальные цены
    const needUpdate = await authStore.checkUserSessionNeedUpdate();

    if (needUpdate) {
      await authStore.updateUserSession();
    }

    try {
      const res = await siteSettingsApi.downloadPriceList();

      if (isInvalidResponse(res)) {
        throw newInvalidResponseError(res);
      }

      await download(
        URL.createObjectURL(res.data),
        `АЛВИМА - Прайс от ${parseDateToFullLocale(new Date())}.xlsx`,
      );
    } catch (e) {
      _l.error(e);
    } finally {
      is.value.priceListLoading = false;
    }
  };

  const getPriceListIsLoading = computed(
    (): boolean => is.value.priceListLoading,
  );

  return {
    downloadPriceList,
    getPriceListIsLoading,
  };
};
